/**
 * Copyright © Veeam Software Group GmbH.
 */

import { transformRequestParams } from '@veeam-vspc/components/src/Grid/helpers';
import { FilterCollation, SortDirection } from '@veeam-vspc/components';

import type { RequestParams } from '@veeam-vspc/components';
import type {
    Vb365BackupJob,
    Vb365BackupProxy,
    Vb365BackupRepository,
    Vb365CopyJob,
    Vb365Job,
    Vb365OrganizationBase,
} from '@veeam-vspc/models/rest';
import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';

import { core } from 'core/core-module';
import { VboExpandParams } from '../enums';

import type { Vb365OrganizationBaseFix } from 'core/interfaces';

export class VBObjectsService {
    static getJobs(params: RequestParams<unknown>) {
        return core.transportService
            .get<{}, Vb365Job[]>(
                '/infrastructure/vb365Servers/organizations/jobs',
                transformRequestParams(params)
            );
    }

    static loadOrganizations() {
        const minServerVersion = 7;

        return core.transportService.get<{}, Vb365OrganizationBase[]>(
            '/infrastructure/vb365Servers/organizations',
            {
                expand: [VboExpandParams.Vb365Server],
                sort: JSON.stringify([{
                    property: 'name',
                    direction: SortDirection.Asc,
                    collation: FilterCollation.Ignorecase,
                }]),
            }
        ).then((resp: BaseSuccessRequestResponse<Vb365OrganizationBaseFix[]>) => ({
            ...resp,
            data: (resp.data || []).filter(x => x._embedded.vb365Server.majorVersion >= minServerVersion),
        }));
    }

    static loadBackupRepositories(vb365ServerUid: string) {
        return core.transportService.get<{}, Vb365BackupRepository[]>(
            `/infrastructure/vb365Servers/${vb365ServerUid}/backupRepositories`,
            { sort: JSON.stringify([{ property: 'name', direction: SortDirection.Asc, collation: FilterCollation.Ignorecase }]) }
        )
            .then((response: BaseSuccessRequestResponse<Vb365BackupRepository[]>) => response.data);
    }

    static loadProxies(vb365ServerUid: string) {
        return core.transportService.get<void, Vb365BackupProxy[]>(`/infrastructure/vb365Servers/${vb365ServerUid}/backupProxies`)
            .then((response: BaseSuccessRequestResponse<Vb365BackupProxy[]>) => response.data);
    }

    static loadBackupJobData(job: Vb365Job) {
        return core.transportService
            .get<unknown, Vb365BackupJob>(`/infrastructure/vb365Servers/${job.vb365ServerUid}/organizations/jobs/backup/${job.instanceUid}`)
            .then((response: BaseSuccessRequestResponse<Vb365BackupJob>) => response.data);
    }

    static loadCopyJobData(job: Vb365Job) {
        return core.transportService
            .get<unknown, Vb365CopyJob>(`/infrastructure/vb365Servers/${job.vb365ServerUid}/organizations/jobs/copy/${job.instanceUid}`)
            .then((response: BaseSuccessRequestResponse<Vb365CopyJob>) => response.data);
    }
}
